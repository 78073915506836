<template>
  <div class="home">
    <section class="banner">
      <img src="../assets/imgs/P1.png" class="title" />
    </section>
    <section class="earn">
      <img src="../assets/imgs/earn.png" />
      <div class="box-flex">
        <div class="box-item">
          <img src="../assets/imgs/E1.png" />
        </div>
        <div class="box-item">
          <img src="../assets/imgs/E2.png" />
        </div>
        <div class="box-item">
          <img src="../assets/imgs/E3.png" />
        </div>
      </div>
      <div class="box-flex">
        <div class="box-item">
          <img src="../assets/imgs/E4.png" />
        </div>
        <div class="box-item">
          <img src="../assets/imgs/E5.png" />
        </div>
        <div class="box-item">
          <img src="../assets/imgs/E6.png" />
        </div>
      </div>
      <p>* 在以上各场景使用现金消费，可以得到相应数额的珑珠返还</p>
    </section>
    <section class="consume">
      <img src="../assets/imgs/consume.png" class="title" />
      <div class="box-flex" style="width: 90%; margin: 0 auto;">
        <div class="flex-item"><img src="../assets/imgs/C1.png" /></div>
        <div class="flex-item"><img src="../assets/imgs/C2.png" /></div>
        <div class="flex-item"><img src="../assets/imgs/C3.png" /></div>
        <div class="flex-item"><img src="../assets/imgs/C4.png" /></div>
      </div>
      <div class="box-flex" style="margin-top: 12px;">
        <div class="flex-item"><img src="../assets/imgs/C5.png" /></div>
        <div class="flex-item"><img src="../assets/imgs/C6.png" /></div>
        <div class="flex-item"><img src="../assets/imgs/C7.png" /></div>
        <div class="flex-item"><img src="../assets/imgs/C8.png" /></div>
        <div class="flex-item"><img src="../assets/imgs/C9.png" /></div>
      </div>
      <p>* 10珑珠抵1元人民币<span>珑珠用户等级越高抵扣越多</span></p>
      <p class="more">...</p>
      <p class="info">更多权益持续上线中</p>
      <p class="info">欢迎下载体验</p>
    </section>
    <section class="aboutme">
      <h2 style="text-align: center;">关于我们</h2>
      <p>珑珠App是由北京珑智数字科技有限公司开发并提供的一站式生活服务平台。</p>
      <p>
        珑珠用户在珑珠App中可以享受到优质的消费体验，完成各个生活场景的流畅切换，拥有更快捷、更高效、更有品质的即时生活服务。珑珠App正在成为珑珠用户和互联网用户追求美好生活的目的地。
      </p>
    </section>
    <footer>
      <h2 style="text-align: center;">联系我们</h2>
      <p><label>官方邮箱</label><span>it_support@longzhubao.com</span></p>
      <p><label>联系电话</label><span>010-87984298</span></p>
      <p>
        <label>备案/许可证编号</label
        ><span
          ><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
            >京ICP备2021023837号-1</a
          ></span
        >
      </p>
      <p><label>网络文化经营许可证（编号）：</label><span>京网文（2021）4088-1042号</span></p>
      <img src="../assets/imgs/P4.png" />
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>
<style lang="less" scoped>
@import './home.less';
</style>
<style lang="less" scoped>
.home {
  background: #fff;
}
</style>
